import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Statistic } from '@b3w/react-statistic';
import { PrivacyPolicy } from '@b3w/pp-portal';
import { Preloader, Audio } from 'components';
import routes from 'routes';
import useAudio from 'hooks/useAudio';
import Banners from 'components/Banners/Banners';
import { AudioSpeedControllerPortal } from 'components/AudioSpeedControllerPortal';
import APILinks from 'constants/api';
import '@b3w/pp-portal/dist/portal-bundle.css';

// Component
const RouterProvider = () => {
    const [, handlePlay, handlePause] = useAudio();
    const [text, setText] = useState<string | null>(null);

    useEffect(() => {
        fetch(APILinks.PRIVACY_FOOTER)
            .then((res) => res.text())
            .then((resText) => {
                setText(resText);
            })
            .catch(() => {});
    }, []);

    return (
        <Suspense
            fallback={<Preloader />}
        >
            <BrowserRouter>
                {text ? <PrivacyPolicy dangerousText={text} adoption /> : null}
                <Statistic path="/referer/statistic" from timeoutMinutes={1} />
                {renderRoutes(routes, {
                    handlePlay,
                    handlePause,
                })}
                <Route
                    render={() => (
                        <Audio
                            handlePlay={handlePlay}
                            handlePause={handlePause}
                        />
                    )}
                />
                <Route
                    render={() => (
                        <AudioSpeedControllerPortal />
                    )}
                />
                <Route
                    render={() => (
                        <Banners />
                    )}
                />
            </BrowserRouter>
        </Suspense>
    );
};

export default RouterProvider;
